@font-face {
  font-family: 'bitsoglyphs';
  src: url('bitsoglyphs.eot?key4ks');
  src: url('bitsoglyphs.eot?key4ks#iefix') format('embedded-opentype'), url('bitsoglyphs.ttf?key4ks') format('truetype'),
    url('bitsoglyphs.woff?key4ks') format('woff'), url('bitsoglyphs.svg?key4ks#bitsoglyphs') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='bitso-'],
[class*=' bitso-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bitsoglyphs' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
