@font-face {
  font-family: 'moon';
  src:  url('fonts/moon.eot?qygbxd');
  src:  url('fonts/moon.eot?qygbxd#iefix') format('embedded-opentype'),
    url('fonts/moon.ttf?qygbxd') format('truetype'),
    url('fonts/moon.woff?qygbxd') format('woff'),
    url('fonts/moon.svg?qygbxd#moon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="moon-"], [class*=" moon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'moon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.moon-key:before {
  content: "\e951";
  color: #63727c;
}
.moon-edit_outline:before {
  content: "\e952";
  color: #63727c;
}
.moon-edit_filled:before {
  content: "\e953";
  color: #63727c;
}
.moon-delete_filled:before {
  content: "\e954";
  color: #63727c;
}
.moon-delete_outline:before {
  content: "\e955";
  color: #63727c;
}
.moon-delete_forever_filed:before {
  content: "\e956";
  color: #63727c;
}
.moon-delete_forever_outline:before {
  content: "\e957";
  color: #63727c;
}
.moon-qr_scanner:before {
  content: "\e959";
  color: #63727c;
}
.moon-zoom:before {
  content: "\e950";
}
.moon-user_avatar2:before {
  content: "\e948";
}
.moon-text_doc:before {
  content: "\e944";
}
.moon-time:before {
  content: "\e947";
}
.moon-bitso_transfer:before {
  content: "\e943";
}
.moon-pix:before {
  content: "\e94f";
}
.moon-left_ios:before {
  content: "\e92d";
}
.moon-left_and:before {
  content: "\e92e";
}
.moon-down_and:before {
  content: "\e92f";
}
.moon-down_ios:before {
  content: "\e930";
}
.moon-right_and:before {
  content: "\e932";
}
.moon-right_ios:before {
  content: "\e934";
}
.moon-options_and:before {
  content: "\e935";
}
.moon-options_ios:before {
  content: "\e936";
}
.moon-up_and:before {
  content: "\e938";
}
.moon-up_ios:before {
  content: "\e93a";
}
.moon-remove:before {
  content: "\e93b";
}
.moon-qrcode:before {
  content: "\e93c";
}
.moon-menu_lines:before {
  content: "\e93d";
}
.moon-information:before {
  content: "\e93e";
}
.moon-alert:before {
  content: "\e93f";
}
.moon-copy:before {
  content: "\e940";
}
.moon-check:before {
  content: "\e941";
}
.moon-add:before {
  content: "\e942";
}
.moon-arrow_reload:before {
  content: "\e92c";
}
.moon-arrow_deposit:before {
  content: "\e945";
}
.moon-arrow_withdrawal:before {
  content: "\e946";
}
.moon-info:before {
  content: "\e92b";
}
.moon-play:before {
  content: "\e92a";
}
.moon-val_check:before {
  content: "\e927";
}
.moon-val_fail:before {
  content: "\e928";
}
.moon-val_null:before {
  content: "\e929";
}
.moon-Bulb:before {
  content: "\e925";
}
.moon-spinner:before {
  content: "\e95a";
}
.moon-devices:before {
  content: "\e937";
}
.moon-laptop:before {
  content: "\e939";
}
.moon-tablet:before {
  content: "\e958";
}
.moon-hide_password:before {
  content: "\e901";
}
.moon-show_password:before {
  content: "\e902";
}
.moon-image_type:before {
  content: "\e903";
}
.moon-upload_file:before {
  content: "\e904";
}
.moon-delete:before {
  content: "\e905";
}
.moon-phone:before {
  content: "\e906";
}
.moon-email:before {
  content: "\e907";
}
.moon-lock:before {
  content: "\e908";
}
.moon-unlock:before {
  content: "\e949";
}
.moon-trash:before {
  content: "\e917";
}
.moon-help:before {
  content: "\e909";
}
.moon-user_avatar:before {
  content: "\e90a";
}
.moon-language:before {
  content: "\e90b";
}
.moon-arrow_bottom:before {
  content: "\e90c";
}
.moon-arrow_convert:before {
  content: "\e90d";
}
.moon-arrow_left:before {
  content: "\e90e";
}
.moon-arrow_leftbottom:before {
  content: "\e90f";
}
.moon-arrow_lefttop:before {
  content: "\e910";
}
.moon-arrow_right:before {
  content: "\e911";
}
.moon-arrow_rightbottom:before {
  content: "\e912";
}
.moon-arrow_righttop:before {
  content: "\e913";
}
.moon-arrow_top:before {
  content: "\e914";
}
.moon-arrow_turnleft:before {
  content: "\e915";
}
.moon-arrow_turnright:before {
  content: "\e916";
}
.moon-arrow_doubleright:before {
  content: "\e931";
}
.moon-dollar:before {
  content: "\e918";
}
.moon-bitcoin:before {
  content: "\e919";
}
.moon-ethereum:before {
  content: "\e91a";
}
.moon-xrp:before {
  content: "\e91b";
}
.moon-litecoin:before {
  content: "\e91c";
}
.moon-bcash:before {
  content: "\e926";
}
.moon-tusd:before {
  content: "\e924";
}
.moon-mana:before {
  content: "\e922";
}
.moon-golem:before {
  content: "\e91f";
}
.moon-bat:before {
  content: "\e920";
}
.moon-dai:before {
  content: "\e921";
}
.moon-busd:before {
  content: "\e94a";
}
.moon-paxos:before {
  content: "\e94b";
}
.moon-usdt:before {
  content: "\e94c";
}
.moon-usdc:before {
  content: "\e94d";
}
.moon-celousd:before {
  content: "\e94e";
}
.moon-no_coin:before {
  content: "\e933";
}
.moon-x_cross:before {
  content: "\e91d";
}
.moon-x_cross_mobile:before {
  content: "\e91e";
}
.moon-dropdown_arrow:before {
  content: "\e900";
}
.moon-select_arrow:before {
  content: "\e923";
}
