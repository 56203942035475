$small: 320px;
$small-medium: 600px;
$medium: 720px;
$medium-large: 840px;
$large: 1024px;
$extra-large: 1440px;

$small-length: 4;
$medium-length: 8;
$large-length: 12;

$items-list: $small $small-length 'xs', $small-medium $medium-length 'sm', $medium $medium-length 'md',
  $medium-large $large-length 'ml', $large $large-length 'lg', $extra-large $large-length 'xl';

.Grid {
  grid-template-columns: repeat($small-length, 1fr);
  column-gap: 16px;
  &.withPaddings {
    padding: 0 16px;
  }

  @media screen and (min-width: $small-medium) {
    grid-template-columns: repeat($medium-length, 1fr);
    column-gap: 24px;
    &.withPaddings {
      padding: 0 24px;
    }
  }

  @media screen and (min-width: $medium) {
    grid-template-columns: repeat($medium-length, 1fr);
    column-gap: 24px;
  }

  @media screen and (min-width: $medium-large) {
    grid-template-columns: repeat($large-length, 1fr);
    column-gap: 32px;
  }

  @media screen and (min-width: $large) {
    grid-template-columns: repeat($large-length, 1fr);
    column-gap: 32px;
    &.withPaddings {
      padding: 0 32px;
    }
  }

  @media screen and (min-width: $extra-large) {
    grid-template-columns: repeat($large-length, 1fr);
    column-gap: 40px;
  }
}

@mixin grid-column($size) {
  grid-column: span + ' ' + $size;
}

@mixin grid-column-start-end($start, $end: false) {
  grid-column-start: $start;
  @if $end {
    grid-column-end: $end;
  } @else {
    grid-column-end: -1;
  }
}

@mixin get-grid-columns($name, $loop) {
  @for $i from 1 through $loop {
    .grid-#{$name}-#{$i} {
      @include grid-column($i);
    }
    @for $j from 1 through $loop {
      .grid-#{$name}-#{$i}-to-#{$j} {
        @include grid-column-start-end($i, $j);
      }
      .grid-#{$name}--#{$i}-to-#{$j} {
        @include grid-column-start-end(-$i, $j);
      }
      .grid-#{$name}-#{$i}-to--#{$j} {
        @include grid-column-start-end($i, -$j);
      }
      .grid-#{$name}--#{$i}-to--#{$j} {
        @include grid-column-start-end(-$i, -$j);
      }
      .grid-#{$name}-#{$i}-to-end {
        @include grid-column-start-end($i);
      }
      .grid-#{$name}--#{$i}-to-end {
        @include grid-column-start-end(-$i);
      }
    }
  }
}

@each $item in $items-list {
  $size: nth($item, 1);
  $loop: nth($item, 2);
  $name: nth($item, 3);
  @if ($name == 'xs') {
    @include get-grid-columns($name, $loop);
  } @else {
    @media screen and (min-width: $size) {
      @include get-grid-columns($name, $loop);
    }
  }
}
