@import '../moon/particles/Icon/icons/style.css';
@import '../moon/particles/Icon/icons/svgStyle.css';
$screen-wide: 1920px;
$screen-desktop: 1280px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;
$phone-xs: 320px;
$phone-sm: 360px;
$phone-md: 375px;
$phone-lg: 411px;
$phone-xl: 414px;

@function getWidth($width, $gap, $cols: 1) {
  $result: 0;
  $result: $cols * $width + ($cols - 1) * $gap;
  @return $result;
}

@media screen and (min-width: $phone-xs) {
  .col-xs-1 {
    width: getWidth(55, 20, 1) * 1px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .col-xs-2 {
    width: getWidth(55, 20, 2) * 1px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .col-xs-3 {
    width: getWidth(55, 20, 3) * 1px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .col-xs-4 {
    width: getWidth(55, 20, 4) * 1px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

@media screen and (min-width: $phone-sm) {
  .col-sm-1 {
    width: getWidth(65, 20, 1) * 1px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .col-sm-2 {
    width: getWidth(65, 20, 2) * 1px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .col-sm-3 {
    width: getWidth(65, 20, 3) * 1px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  .col-sm-4 {
    width: getWidth(65, 20, 4) * 1px;
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

@media screen and (min-width: $phone-md) {
  .col-md-1 {
    width: getWidth(68, 21, 1) * 1px;
    &:not(:last-child) {
      margin-right: 21px;
    }
  }
  .col-md-2 {
    width: getWidth(68, 21, 2) * 1px;
    &:not(:last-child) {
      margin-right: 21px;
    }
  }
  .col-md-3 {
    width: getWidth(68, 21, 3) * 1px;
    &:not(:last-child) {
      margin-right: 21px;
    }
  }
  .col-md-4 {
    width: getWidth(68, 21, 4) * 1px;
    &:not(:last-child) {
      margin-right: 21px;
    }
  }
}

@media screen and (min-width: $phone-lg) {
  .col-lg-1 {
    width: getWidth(77, 21, 1) * 1px;
    &:not(:last-child) {
      margin-right: 21px;
    }
  }
  .col-lg-2 {
    width: getWidth(77, 21, 2) * 1px;
    &:not(:last-child) {
      margin-right: 21px;
    }
  }
  .col-lg-3 {
    width: getWidth(77, 21, 3) * 1px;
    &:not(:last-child) {
      margin-right: 21px;
    }
  }
  .col-lg-4 {
    width: getWidth(77, 21, 4) * 1px;
    &:not(:last-child) {
      margin-right: 21px;
    }
  }
}

@media screen and (min-width: $phone-xl) {
  .col-xl-1 {
    width: getWidth(77, 22, 1) * 1px;
    &:not(:last-child) {
      margin-right: 22px;
    }
  }
  .col-xl-2 {
    width: getWidth(77, 22, 2) * 1px;
    &:not(:last-child) {
      margin-right: 22px;
    }
  }
  .col-xl-3 {
    width: getWidth(77, 22, 3) * 1px;
    &:not(:last-child) {
      margin-right: 22px;
    }
  }
  .col-xl-4 {
    width: getWidth(77, 22, 4) * 1px;
    &:not(:last-child) {
      margin-right: 22px;
    }
  }
}

@media screen and (min-width: $tablet-portrait) {
  .col-tp-1 {
    width: getWidth(33, 28, 1) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  .col-tp-2 {
    width: getWidth(33, 28, 2) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  .col-tp-3 {
    width: getWidth(33, 28, 3) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  .col-tp-4 {
    width: getWidth(33, 28, 4) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  .col-tp-5 {
    width: getWidth(33, 28, 5) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  .col-tp-6 {
    width: getWidth(33, 28, 6) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  .col-tp-7 {
    width: getWidth(33, 28, 7) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  .col-tp-8 {
    width: getWidth(33, 28, 8) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  .col-tp-9 {
    width: getWidth(33, 28, 9) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  .col-tp-10 {
    width: getWidth(33, 28, 10) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  .col-tp-11 {
    width: getWidth(33, 28, 11) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
  .col-tp-12 {
    width: getWidth(33, 28, 12) * 1px;
    &:not(:last-child) {
      margin-right: 28px;
    }
  }
}

@media screen and (min-width: $tablet-landscape) {
  .col-tl-1 {
    width: getWidth(44, 32, 1) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .col-tl-2 {
    width: getWidth(44, 32, 2) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .col-tl-3 {
    width: getWidth(44, 32, 3) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .col-tl-4 {
    width: getWidth(44, 32, 4) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .col-tl-5 {
    width: getWidth(44, 32, 5) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .col-tl-6 {
    width: getWidth(44, 32, 6) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .col-tl-7 {
    width: getWidth(44, 32, 7) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .col-tl-8 {
    width: getWidth(44, 32, 8) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .col-tl-9 {
    width: getWidth(44, 32, 9) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .col-tl-10 {
    width: getWidth(44, 32, 10) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .col-tl-11 {
    width: getWidth(44, 32, 11) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
  .col-tl-12 {
    width: getWidth(44, 32, 12) * 1px;
    &:not(:last-child) {
      margin-right: 32px;
    }
  }
}

@media screen and (min-width: $screen-desktop) {
  .col-sd-1 {
    width: getWidth(60, 40, 1) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sd-2 {
    width: getWidth(60, 40, 2) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sd-3 {
    width: getWidth(60, 40, 3) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sd-4 {
    width: getWidth(60, 40, 4) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sd-5 {
    width: getWidth(60, 40, 5) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sd-6 {
    width: getWidth(60, 40, 6) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sd-7 {
    width: getWidth(60, 40, 7) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sd-8 {
    width: getWidth(60, 40, 8) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sd-9 {
    width: getWidth(60, 40, 9) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sd-10 {
    width: getWidth(60, 40, 10) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sd-11 {
    width: getWidth(60, 40, 11) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sd-12 {
    width: getWidth(60, 40, 12) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}
@media screen and (min-width: $screen-wide) {
  .col-sw-1 {
    width: getWidth(80, 40, 1) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sw-2 {
    width: getWidth(80, 40, 2) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sw-3 {
    width: getWidth(80, 40, 3) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sw-4 {
    width: getWidth(80, 40, 4) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sw-5 {
    width: getWidth(80, 40, 5) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sw-6 {
    width: getWidth(80, 40, 6) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sw-7 {
    width: getWidth(80, 40, 7) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sw-8 {
    width: getWidth(80, 40, 8) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sw-9 {
    width: getWidth(80, 40, 9) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sw-10 {
    width: getWidth(80, 40, 10) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sw-11 {
    width: getWidth(80, 40, 11) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
  .col-sw-12 {
    width: getWidth(80, 40, 12) * 1px;
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}
