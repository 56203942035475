@import '@legacy/styles/next.custom';
@import '@glidejs/glide/dist/css/glide.core.min.css';
@import '@glidejs/glide/dist/css/glide.theme.min.css';
@import 'bootstrap/scss/bootstrap';

/**
 * Fix `next-images` package to work with legacy-bundled and new versions of Next.js versions.
 */
.next-img-wrapper > span {
  position: unset !important;

  img {
    &[style] {
      width: 100% !important;
      min-width: 100% !important;
      @media screen and (min-width: 768px) {
        width: auto !important;
      }
    }
  }
  width: 118px !important;
  @media screen and (min-width: 768px) {
    width: 120px !important;
  }
  @media screen and (min-width: 1024px) {
    width: 156px !important;
  }
  @media screen and (min-width: 1280px) {
    width: 178px !important;
  }
}

.nextjs-image-fix {
  > span {
    height: 100% !important;

    img {
      object-fit: contain;
    }
  }
}
