.svg-icon {
  display: inline-block;
  mask-size: 1em;
  background-color: currentColor;
  background-size: 1em 1em;
  width: 1em;
  height: 1em;
}

.moon-bitso {
  mask-image: url('./svgs/BitsoTransfer.svg');
}

.moon-bank {
  mask-image: url('./svgs/Banking.svg');
}

.moon-cash {
  mask-image: url('./svgs/Cash.svg');
}

.moon-card {
  mask-image: url('./svgs/Card.svg');
}

.moon-arrows {
  mask-image: url('./svgs/UpDownArrows.svg');
}

.moon-flag {
  mask-image: url('./svgs/Union.svg');
}

.moon-wallet {
  mask-image: url('./svgs/Wallet.svg');
}

.moon-mobile-phone {
  mask-image: url('./svgs/Phone.svg');
}

.moon-whatsapp {
  mask-image: url('./svgs/Whatsapp.svg');
}

