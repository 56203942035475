html {
  scroll-behavior: smooth !important;
}

html,
#__next,
body {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.fade-in {
  animation: fadeIn 1s;
}

.fade-in-out {
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}

.fade-in-out.show {
  opacity: 1;
  visibility: visible;
}

@keyframes vertical-slide {
  0% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(5px);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(80px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes fadeWithDelay25 {
  0%,
  75% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeWithDelay50 {
  0%,
  50% {
    opacity: 0;
    transform: scale(0.5);
  }
  75%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes slide {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes slide-reverse {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.95);
  }
}

@keyframes pulse-fade {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }
  70% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes stroke-flowing {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 192;
  }
}

/* Transition Animation Percentage */

@property --percent {
  syntax: '<number>';
  initial-value: 0;
  inherits: false;
}
@property --decimal-separator {
  syntax: '<string>';
  initial-value: '.';
  inherits: false;
}
@property --percent-temp {
  syntax: '<number>';
  initial-value: 0;
  inherits: false;
}
@property --percent-temp-v1 {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}
@property --percent-temp-v2 {
  syntax: '<integer>';
  initial-value: 0;
  inherits: false;
}

.percentage-animation {
  transition: --percent 1s;
  --percent-temp: var(--percent);
  --decimal-sep: var(--decimal-separator);
  --percent-temp-v1: max(var(--percent-temp) - 0.5, 0);
  --percent-temp-v2: max((var(--percent-temp) - var(--percent-temp-v1)) * 100 - 0.5, 0);
  counter-reset: percent-temp-v1 var(--percent-temp-v1) percent-temp-v2 var(--percent-temp-v2);
}
.percentage-animation::before {
  content: counter(percent-temp-v1) var(--decimal-sep) counter(percent-temp-v2, decimal-leading-zero) '%';
}
